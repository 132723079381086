*, :after, :before {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

::selection {
  color: #d4e3eb;
  background-color: #002449;
}

html {
  scroll-behavior: smooth;
  font-size: 62.5%;
  overflow-x: hidden;
}

@media (width <= 56.25em) {
  html {
    font-size: 50%;
  }
}

@media (width >= 93.75em) {
  html {
    font-size: 70%;
  }
}

body {
  color: #13253b;
  font-family: Rubik, sans-serif;
  font-size: 2.4rem;
  font-weight: 400;
  line-height: 1.4;
}

.whatsapp__placeholder {
  display: none;
}

.whatsapp--active {
  display: block;
  position: fixed;
  bottom: 2.8rem;
  left: 2.8rem;
}

.whatsapp__placeholder--icon {
  color: #eef4f7;
  opacity: .75;
  background-color: #03a63c;
  border-radius: 50%;
  width: 9.6rem;
  height: 9.6rem;
  padding: 1.2rem;
  transition: all .3s;
  transform: scale(.85);
}

@media (width <= 36.625rem) {
  .whatsapp__placeholder--icon {
    opacity: .9;
    width: 6.8rem;
    height: 6.8rem;
  }
}

.whatsapp__placeholder--icon:hover {
  opacity: 1;
  transform: scale(1);
}

.section {
  transition: all .75s;
}

.section--hidden {
  opacity: 0;
  transform: translateY(12.8rem);
}

.mobile__background--img {
  background-image: linear-gradient(to right, #0024490d, #d4e3ebd9), url("pic 1.51b8095b.jpeg");
  transition: all 5s;
  animation-name: change;
  animation-duration: 15s;
  animation-iteration-count: infinite;
  animation-direction: alternate-reverse;
  animation-play-state: running;
  animation-fill-mode: forwards;
}

@keyframes change {
  0% {
    background-image: url("pic 1.51b8095b.jpeg");
  }

  25% {
    background-image: url("pic 2.c1638578.jpg");
  }

  50% {
    background-image: url("pic 3.1682333f.jpeg");
  }

  75% {
    background-image: url("pic 4.df323071.jpeg");
  }

  100% {
    background-image: url("pic 1.51b8095b.jpeg");
  }
}

.container {
  max-width: 98rem;
  margin: 0 auto;
}

@media (width <= 56.25em) {
  .container {
    max-width: 79rem;
  }
}

@media (width <= 48rem) {
  .container {
    max-width: 63rem;
  }
}

@media (width <= 36.625rem) {
  .container {
    max-width: 37.5rem;
  }
}

.homepage__desktop {
  display: block;
}

@media (width <= 48rem) {
  .homepage__desktop {
    display: none;
  }
}

.homepage__mobile {
  background-color: #eef4f7;
  padding-bottom: 8.4rem;
  display: none;
}

@media (width <= 48rem) {
  .homepage__mobile {
    display: block;
  }
}

.mobile__background {
  background-color: #002449;
  border: none;
  outline: none;
  position: relative;
  overflow-x: hidden;
}

.mobile__background--img {
  clip-path: polygon(0 0, 0 100%, 100% 87.5%, 100% 0);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100vw;
  height: 50vh;
}

.mobile__background--centre {
  text-align: center;
  clip-path: polygon(15% 0, 0 100%, 100% 100%, 100% 0);
  background-color: #d4e3eb26;
  padding: 2.8rem 1.8rem;
  position: absolute;
  bottom: 7.5%;
  right: -4.5%;
  transform: scale(.9);
  box-shadow: 0 1rem 2rem #00000080;
}

@media (width <= 36.625rem) {
  .mobile__background--centre {
    background-color: #eef4f759;
  }
}

.mobile__background--logo {
  width: 14rem;
  height: auto;
}

@media (width <= 36.625rem) {
  .mobile__background--logo {
    width: 9.6rem;
  }
}

.mobile__background--header {
  text-transform: uppercase;
  color: inherit;
  letter-spacing: 2.8px;
  font-size: 5.6rem;
}

@media (width <= 36.625rem) {
  .mobile__background--header {
    font-size: 4.8rem;
  }
}

.mobile__background--sub {
  text-transform: uppercase;
  color: inherit;
  letter-spacing: 2.8px;
  margin-top: 1.2rem;
  font-size: 2rem;
  font-weight: 500;
}

.mobile__body--call {
  color: #d4e3eb;
  background-image: linear-gradient(#002449, #000, #002449);
  justify-content: center;
  align-items: center;
  gap: 2.8rem;
  padding: 2.8rem 1.2rem 1.8rem;
  display: flex;
  transform: translateY(-1px);
  box-shadow: 0 .5rem 1rem #00000040;
}

.mobile__body--call--icon {
  color: inherit;
  align-items: center;
  display: flex;
}

.mobile__body--call--icon ion-icon {
  color: #eef4f7;
  background-color: #03a63c;
  border-radius: 50%;
  width: 9.6rem;
  height: 9.6rem;
  padding: 1.5rem;
  transition: all .3s;
  box-shadow: 0 .5rem 1rem #00000040;
}

@media (width <= 36.625rem) {
  .mobile__body--call--icon ion-icon {
    width: 5.78rem;
    height: 5.78rem;
  }
}

.mobile__body--call--icon ion-icon:active {
  transform: scale(.85);
}

.mobile__body--call--contact {
  font-size: 5.6rem;
}

@media (width <= 36.625rem) {
  .mobile__body--call--contact {
    font-size: 3.6rem;
  }
}

.mobile__body--description {
  text-transform: uppercase;
  margin-top: 4.8rem;
  margin-left: 3.6rem;
  font-size: 3rem;
  font-weight: 400;
}

@media (width <= 36.625rem) {
  .mobile__body--description {
    margin-left: 1.8rem;
  }
}

.mobile__body--services {
  flex-wrap: wrap;
  gap: 1.8rem;
  margin-top: 3.6rem;
  display: flex;
}

.mobile__body--point {
  color: #d4e3eb;
  background-color: #002449;
  border-radius: 3px;
  align-items: center;
  gap: .6rem;
  padding: .6rem;
  font-size: 2.4rem;
  list-style: none;
  display: flex;
}

@media (width <= 36.625rem) {
  .mobile__body--point {
    font-size: 1.8rem;
  }
}

.mobile__menu {
  position: absolute;
  top: 3%;
  left: 5%;
}

.mobile__menu--link {
  color: #d4e3eb;
}

.mobile__menu--link--icon {
  color: #d4e3eb;
  background-color: #002449;
  border-radius: 50%;
  width: 6.8rem;
  height: 6.8rem;
  padding: 1.8rem;
  transition: all .3s;
  transform: scale(.85);
}

.mobile__menu--link--icon:active {
  transform: scale(.8);
}

.mobile__navigation {
  z-index: 1;
  color: #d4e3eb;
  -webkit-backdrop-filter: blur(5px);
  backdrop-filter: blur(5px);
  background-image: linear-gradient(to bottom left, #000000f2, #002449e6);
  width: 100vw;
  height: 100vh;
  transition: transform .3s;
  position: fixed;
  top: 0;
  left: 0;
  transform: translateX(-100%);
}

.mobile__navigation--list {
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  gap: 4.8rem;
  list-style: none;
  display: flex;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

@media (width <= 36.625rem) {
  .mobile__navigation--list {
    gap: 2.8rem;
  }
}

.mobile__navigation--list li:nth-child(odd) {
  transform: rotate(1.5deg);
}

@media (width <= 36.625rem) {
  .mobile__navigation--list li:nth-child(odd) {
    transform: rotate(0);
  }
}

.mobile__navigation--link:link, .mobile__navigation--link:visited {
  color: #13253b;
  text-transform: uppercase;
  letter-spacing: 1.8px;
  background-image: linear-gradient(to bottom right, #d4e3eb 60%, #0086c9);
  padding: 1.8rem 3.6rem;
  font-size: 3.6rem;
  text-decoration: none;
  transition: all .3s;
  display: inline-block;
  box-shadow: 0 1rem 2rem #00000026;
}

@media (width <= 36.625rem) {
  .mobile__navigation--link:link, .mobile__navigation--link:visited {
    font-size: 2.4rem;
  }
}

.mobile__navigation--link:active {
  background-image: linear-gradient(to bottom right, #d4e3eb 25%, #0086c9);
  transform: scale(.9);
  box-shadow: 0 .5rem 1rem #00000040;
}

.mobile__navigation--exit {
  transition: all .2s;
  display: block;
  position: absolute;
  top: 3.6rem;
  right: 4.8rem;
}

.mobile__navigation--exit:active {
  transform: scale(.9);
}

.mobile__navigation--exit--icon {
  color: #d4e3eb;
  width: 6.8rem;
  height: 6.8rem;
}

.mobile__navigation--exit--icon:hover {
  cursor: pointer;
}

.homepage {
  height: 100vh;
  position: relative;
  overflow-y: hidden;
}

@media (width <= 48rem) {
  .homepage {
    height: auto;
  }
}

.homepage__nav {
  background-color: #002449;
  justify-content: space-between;
  align-items: center;
  padding: 1.8rem 5.6rem;
  display: flex;
}

@media (width <= 56.25em) {
  .homepage__nav {
    padding: 1.8rem;
  }
}

@media (width <= 48rem) {
  .homepage__nav {
    padding: 1.8rem 3.6rem;
  }
}

.homepage__nav--company {
  align-items: center;
  gap: 1.8rem;
  display: flex;
}

.homepage__nav--header {
  color: #d4e3eb;
  flex-direction: column;
  align-items: center;
  display: flex;
}

.homepage__nav--header--main {
  text-transform: uppercase;
  letter-spacing: 1.8px;
  font-size: 3rem;
}

@media (width <= 56.25em) {
  .homepage__nav--header--main {
    font-size: 2rem;
  }
}

@media (width <= 48rem) {
  .homepage__nav--header--main {
    letter-spacing: 2.4px;
    font-size: 2.4rem;
  }
}

.homepage__nav--header--sub {
  text-transform: uppercase;
  letter-spacing: 1.6px;
  margin-top: .6rem;
  font-size: 1.6rem;
  font-weight: 400;
}

@media (width <= 56.25em) {
  .homepage__nav--header--sub {
    font-size: 1.2rem;
  }
}

@media (width <= 48rem) {
  .homepage__nav--header--sub {
    font-size: 1.6rem;
  }
}

.homepage__nav--icon {
  width: 9.6rem;
  height: auto;
  transition: all .75s;
  transform: scale(1);
}

.homepage__nav--icon:hover {
  transform: scale(1.25);
}

.homepage__nav--list {
  gap: 3.6rem;
  display: flex;
}

@media (width <= 56.25em) {
  .homepage__nav--list {
    gap: 1.2rem;
  }
}

.homepage__nav--list li {
  color: #d4e3eb;
  background-color: #0000;
  padding: .6rem 1.2rem;
  list-style: none;
  transition: all .3s;
}

.homepage__nav--list li:hover {
  color: #002449;
  background-color: #d4e3eb;
  transform: rotate(-2deg);
}

.homepage__nav--list li:active {
  background-color: #0086c9;
  transform: rotate(-3deg)scale(.85);
}

.homepage__nav--list--link {
  color: inherit;
  text-transform: uppercase;
  font-size: 2.4rem;
  font-weight: 400;
  text-decoration: none;
}

@media (width <= 56.25em) {
  .homepage__nav--list--link {
    font-size: 2rem;
  }
}

.homepage__background {
  background-position: bottom;
  background-size: cover;
  width: 100%;
  height: 100%;
  position: absolute;
}

.homepage__body {
  grid-template-columns: 5fr 3fr;
  justify-content: center;
  align-items: center;
  gap: 5.6rem;
  margin: 0 0 0 1.8rem;
  display: grid;
  overflow: hidden;
}

@media (width >= 93.75em) {
  .homepage__body {
    align-items: flex-start;
  }
}

.homepage__body--contact--description {
  font-size: 2.4rem;
  font-weight: 500;
}

@media (width <= 56.25em) {
  .homepage__body--contact--description {
    font-size: 2rem;
  }

  .homepage__body--contact--number {
    font-size: 3rem;
  }
}

.homepage__body--header {
  color: #002449;
  letter-spacing: 3.6px;
  text-transform: uppercase;
  font-size: 8.4rem;
  font-weight: 800;
}

@media (width <= 56.25em) {
  .homepage__body--header {
    font-size: 4.8rem;
  }
}

@media (width >= 93.75em) {
  .homepage__body--header {
    margin-top: 4.8rem;
  }
}

.homepage__body--details {
  flex-direction: column;
  margin-top: 3.6rem;
  display: flex;
}

.homepage__body--subheader {
  text-transform: uppercase;
  font-size: 2rem;
  font-weight: 500;
}

.homepage__body--description {
  color: #13253b;
  text-align: justify;
  margin-top: 1.2rem;
  font-size: 1.8rem;
  font-weight: 400;
  line-height: 1.6;
}

@media (width <= 56.25em) {
  .homepage__body--description {
    font-size: 1.6rem;
  }
}

@media (width >= 93.75em) {
  .homepage__body--description {
    margin-top: 3.6rem;
  }
}

.homepage__body--cta {
  margin-top: 3.6rem;
  margin-left: 2.8rem;
  display: inline-block;
}

@media (width <= 56.25em) {
  .homepage__body--cta {
    margin-top: 2.3rem;
  }
}

.homepage__body--cta--placeholder {
  color: #d4e3eb;
  background-image: linear-gradient(to bottom right, #13253b, #002449);
  border-radius: 9px;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 2.8rem;
  padding: 1.8rem 4.8rem;
  text-decoration: none;
  transition: all .3s;
  display: flex;
  box-shadow: 0 1rem 2rem #0000001a;
}

.homepage__body--cta--placeholder:hover {
  cursor: pointer;
  background-image: linear-gradient(to bottom right, #000, #13253b);
  transform: scale(1.1);
  box-shadow: 0 2rem 4rem #0003;
}

.homepage__body--cta--placeholder:active {
  background-image: linear-gradient(to right, #002449, #0086c9);
  transform: scale(1);
  box-shadow: 0 1rem 2rem #0000001a;
}

.homepage__body--cta--icon {
  color: #d4e3eb;
  font-size: 4.8rem;
  display: block;
  transform: scale(1.5);
}

.homepage__body--cta--caption {
  color: #d4e3eb;
  letter-spacing: 1.8px;
  text-transform: uppercase;
  font-size: 2rem;
  font-weight: 500;
}

.homepage__body--right {
  width: 100%;
  height: 100%;
}

.homepage__body--right img {
  width: 100%;
  height: 100%;
  display: block;
}

.services {
  color: #d4e3eb;
  background-color: #002449;
  padding: 5.6rem 0;
}

.services__header {
  text-transform: uppercase;
  font-size: 6.4rem;
}

.services__list {
  grid-template-columns: repeat(2, 1fr);
  align-items: center;
  gap: 5.6rem 4.8rem;
  margin-top: 5.6rem;
  display: grid;
}

@media (width <= 48rem) {
  .services__list {
    grid-template-columns: repeat(1, 1fr);
  }
}

.services__list--mobile {
  display: none;
}

@media (width <= 48rem) {
  .services__list--mobile {
    grid-template-columns: repeat(3, 1fr);
    gap: 1.8rem;
    display: grid;
    transform: scale(1.2);
  }
}

.services__list--mobile img {
  width: 100%;
  height: auto;
}

.services__list--description--title {
  text-transform: uppercase;
  letter-spacing: 1.25px;
  font-size: 3rem;
  font-weight: 500;
}

@media (width <= 56.25em) {
  .services__list--description--title {
    font-size: 2.4rem;
  }
}

.services__list--description--explaination {
  text-align: justify;
  margin-top: 2.8rem;
  font-size: 1.6rem;
  line-height: 1.8;
}

@media (width <= 56.25em) {
  .services__list--description--explaination {
    font-size: 1.4rem;
    line-height: 1.6;
  }
}

@media (width <= 48rem) {
  .services__list--description--explaination {
    font-size: 1.8rem;
    line-height: 1.8;
  }
}

.services__list--image {
  border-radius: 9px;
  width: 100%;
  height: auto;
  overflow: hidden;
}

@media (width <= 48rem) {
  .services__list--image {
    display: none;
  }
}

.services__list--image img {
  border-radius: 9px;
  width: 100%;
  height: auto;
  transition: all .5s;
}

.services__list--image img:hover {
  filter: brightness(.75);
  transform: scale(1.1);
}

.work {
  background-color: #eef4f7;
  padding: 7.2rem 0 12.8rem;
}

@media (width <= 48rem) {
  .work {
    padding: 7.2rem 0;
  }
}

@media (width <= 36.625rem) {
  .work {
    padding: 7.2rem 1.2rem;
  }
}

.work .container {
  position: relative;
}

@media (width <= 36.625rem) {
  .work .container {
    max-width: 100% !important;
  }
}

.work__header {
  text-transform: uppercase;
  font-size: 6.4rem;
}

.work__command {
  background-color: #0000;
  border: none;
  display: inline-block;
  position: absolute;
  right: 0%;
}

@media (width <= 36.625rem) {
  .work__command--up {
    transform: translateY(100%);
  }

  .work__command--down {
    transform: translateY(-100%);
  }
}

.work__command--icon {
  color: #002449;
  font-size: 4.8rem;
  transition: all .3s;
  transform: scale(1);
}

.work__command--icon:hover {
  cursor: pointer;
  color: #0086c9;
  transform: scale(1.25);
}

.work__command--icon:active {
  color: #002449;
  transform: scale(1);
}

.work__container {
  grid-template-rows: repeat(2, 1fr);
  gap: 7.2rem;
  max-height: 66.2rem;
  margin-top: 7.2rem;
  padding: 3.6rem 0 3.6rem 7.2rem;
  display: grid;
  overflow: hidden;
}

@media (width <= 36.625rem) {
  .work__container {
    padding-left: 3.6rem;
  }
}

.work__row {
  height: 27.5rem;
  transition: all .3s;
}

.work__row--pic {
  float: left;
  clip-path: circle(50%);
  -webkit-shape-outside: circle(50% at 50% 50%);
  shape-outside: circle(50% at 50% 50%);
  border-radius: 50%;
  width: 24rem;
  height: 24rem;
  overflow: hidden;
  transform: translate(-20%, -10%);
}

@media (width <= 48rem) {
  .work__row--pic {
    width: 18rem;
    height: 18rem;
  }
}

.work__row--pic img {
  filter: brightness(.8);
  width: auto;
  height: 100%;
  transition: all .3s;
  transform: scale(1.15);
}

@media (width <= 48rem) {
  .work__row--pic img {
    filter: brightness();
    transform: scale(1);
  }
}

.work__row--text--header {
  text-transform: uppercase;
  font-size: 2.4rem;
  font-weight: 500;
}

@media (width <= 48rem) {
  .work__row--text--header {
    font-size: 2rem;
  }
}

.work__row--text--description {
  text-align: justify;
  margin-top: 2.8rem;
  font-size: 2rem;
  line-height: 1.8;
}

@media (width <= 56.25em) {
  .work__row--text--description {
    margin-top: 1.8rem;
    font-size: 1.8rem;
  }
}

@media (width <= 48rem) {
  .work__row--text--description {
    font-size: 1.6rem;
    line-height: 1.6;
  }
}

@media (width <= 36.625rem) {
  .work__row--text--description {
    margin-top: 1.2rem;
    line-height: 1.4;
  }
}

.work__row:hover .work__row--pic img {
  filter: brightness();
  transform: scale(1);
}

.attributes {
  background-color: #d4e3eb;
  padding: 7.2rem 0;
}

.attributes__header {
  text-transform: uppercase;
  font-size: 6.4rem;
}

.attributes__cards {
  text-align: center;
  grid-template-rows: repeat(2, 1fr);
  grid-template-columns: repeat(3, 1fr);
  gap: 4.8rem;
  margin: 7.2rem 0;
  display: grid;
}

@media (width <= 48rem) {
  .attributes__cards {
    grid-template-rows: repeat(3, 1fr);
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (width <= 36.625rem) {
  .attributes__cards {
    grid-template-rows: repeat(6, 1fr);
    grid-template-columns: repeat(1, 1fr);
    margin: 7.2rem 0 4.8rem;
  }
}

.attributes__cards--card {
  color: #d4e3eb;
  background-color: #002449;
  border-radius: 7px;
  padding: 2.8rem 1.8rem;
  transition: all .3s;
}

.attributes__cards--card:hover {
  cursor: pointer;
  color: #eef4f7;
  background-color: #0086c9;
  transform: scale(1.1)rotate(-1deg);
}

.attributes__cards--icon {
  font-size: 3.6rem;
  transition: all .3s;
}

.attributes__cards--header {
  text-transform: uppercase;
  margin-top: 2.8rem;
  font-size: 2rem;
  font-weight: 500;
}

.attributes__cards--caption {
  margin-top: 1.2rem;
  font-size: 1.6rem;
  line-height: 1.6;
}

.attributes__cards--card:hover ion-icon {
  transform: scale(1.25);
}

.reviews {
  color: #d4e3eb;
  background-color: #002449;
  padding: 7.2rem 0;
}

.reviews__all {
  flex-direction: row;
  align-items: stretch;
  gap: 5.6rem;
  margin: 7.2rem 0;
  display: flex;
}

@media (width <= 48rem) {
  .reviews__all {
    flex-direction: column;
  }
}

.reviews__header {
  text-transform: uppercase;
  font-size: 6.4rem;
}

.reviews__card {
  color: #002449;
  background-color: #d4e3eb;
  border-radius: 9px;
  flex: 1;
  width: 100%;
  padding: 0 0 2.8rem;
  transition: all .3s;
  box-shadow: 1rem 2rem 4rem #0003;
}

.reviews__card--top {
  clip-path: ellipse(80% 50%);
  background-color: #eef4f7;
  padding: 2.8rem 2.8rem 5.6rem;
  transition: all .3s;
}

@media (width <= 56.25em) {
  .reviews__card--top {
    padding: 2.8rem 1.2rem;
  }
}

.reviews__card--quote {
  letter-spacing: 2.4px;
  font-size: 8.4rem;
  font-weight: 500;
  line-height: 1;
}

@media (width <= 56.25em) {
  .reviews__card--quote {
    font-size: 6.4rem;
  }
}

.reviews__card--review {
  text-align: center;
  font-size: 1.6rem;
  line-height: 1.4;
}

@media (width <= 56.25em) {
  .reviews__card--review {
    font-size: 1.2rem;
    line-height: 1.6;
  }
}

@media (width <= 48rem) {
  .reviews__card--review {
    font-size: 1.6rem;
  }
}

.reviews__card--bottom {
  justify-content: space-around;
  align-items: center;
  margin: 1.8rem 0 0;
  display: flex;
}

.reviews__card--avatar {
  width: 8.4rem;
  height: auto;
  font-size: 3.6rem;
}

.reviews__card--name {
  text-transform: uppercase;
  text-align: center;
  font-size: 2rem;
}

@media (width <= 56.25em) {
  .reviews__card--name {
    font-size: 1.6rem;
  }
}

@media (width <= 48rem) {
  .reviews__card--name {
    font-size: 2rem;
  }
}

.reviews__card:hover .reviews__card--top {
  cursor: default;
}

.products {
  background-color: #eef4f7;
  padding: 7.2rem 0;
}

.products__gallery--header--title {
  text-transform: uppercase;
  font-size: 6.4rem;
}

.products__grid {
  grid-template-rows: repeat(5, 1fr);
  grid-template-columns: repeat(4, 1fr);
  gap: 1.2rem;
  margin-top: 5.6rem;
  line-height: 1;
  display: grid;
}

@media (width <= 48rem) {
  .products__grid {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media (width <= 36.625rem) {
  .products__grid {
    grid-template-columns: repeat(2, 1fr);
  }
}

.products__grid--figure {
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  display: flex;
  overflow: hidden;
}

.products__grid--figure--hide {
  display: block;
}

@media (width <= 36.625rem) {
  .products__grid--figure--hide {
    display: none;
  }
}

.products__grid--figure img {
  filter: brightness(.85);
  width: 100%;
  height: auto;
  transition: all .3s;
}

@media (width <= 48rem) {
  .products__grid--figure img {
    filter: brightness();
  }
}

.products__grid--figure img:hover {
  filter: brightness();
  transform: scale(1.15);
}

.footer {
  color: #d4e3eb;
  background-color: #002449;
  padding: 4.8rem 1.8rem;
}

@media (width <= 36.625rem) {
  .footer {
    padding: 4.8rem 1.8rem 7.2rem;
  }
}

.footer__tagline {
  text-transform: capitalize;
  text-align: center;
  font-size: 1.6rem;
  font-weight: 400;
}

@media (width <= 48rem) {
  .footer__tagline {
    font-size: 2rem;
  }
}

@media (width <= 36.625rem) {
  .footer__tagline {
    font-size: 1.6rem;
  }
}

.footer__links {
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  gap: 3.6rem;
  margin-top: 4.8rem;
  display: flex;
}

@media (width <= 56.25em) {
  .footer__links {
    gap: 1.8rem;
  }
}

@media (width <= 36.625rem) {
  .footer__links {
    flex-direction: column;
    gap: 6.4rem;
  }
}

.footer__logo {
  flex-direction: column;
  flex-grow: 1;
  align-items: center;
  width: 100%;
  display: flex;
}

@media (width <= 48rem) {
  .footer__logo {
    display: none;
  }
}

.footer__logo img {
  width: 8rem;
  height: auto;
  margin-bottom: 1.8rem;
}

.footer__logo--header {
  text-transform: uppercase;
  margin-bottom: .6rem;
  font-size: 2.4rem;
}

@media (width <= 56.25em) {
  .footer__logo--header {
    font-size: 2rem;
  }
}

.footer__logo--sub {
  text-transform: uppercase;
  font-size: 1.6rem;
  font-weight: 500;
}

@media (width <= 56.25em) {
  .footer__logo--sub {
    font-size: 1.2rem;
  }
}

.footer__nav {
  flex-grow: 1;
  width: 100%;
}

@media (width <= 56.25em) {
  .footer__nav {
    width: 75%;
  }
}

@media (width <= 48rem) {
  .footer__nav {
    width: 100%;
  }
}

.footer__nav--list {
  grid-template-columns: repeat(2, 1fr);
  gap: 1.2rem;
  list-style: none;
  display: grid;
}

.footer__nav--list li {
  color: inherit;
  background-color: #0000;
  align-items: center;
  padding: 1.2rem;
  transition: all .3s;
  display: flex;
  box-shadow: 0 1rem 2rem #0000;
}

@media (width <= 56.25em) {
  .footer__nav--list li {
    padding: .6rem;
  }
}

@media (width <= 36.625rem) {
  .footer__nav--list li {
    justify-content: center;
  }
}

.footer__nav--list li:before {
  content: ">";
  padding-right: 1.8rem;
}

.footer__nav--list li:hover {
  color: #002449;
  background-color: #d4e3eb;
  transform: scale(1.1)rotate(-1deg);
  box-shadow: 0 2rem 4rem #0000001a;
}

.footer__nav--list--link {
  color: inherit;
  font-size: 1.6rem;
  text-decoration: none;
}

@media (width <= 56.25em) {
  .footer__nav--list--link {
    font-size: 1.2rem;
  }
}

@media (width <= 48rem) {
  .footer__nav--list--link {
    font-size: 1.6rem;
  }
}

.footer__info {
  width: 85%;
}

@media (width <= 48rem) {
  .footer__info {
    width: 100%;
  }
}

.footer__info--list {
  flex-direction: column;
  gap: 1.8rem;
  display: flex;
}

.footer__info--item {
  justify-content: flex-start;
  align-items: center;
  gap: 3.6rem;
  display: flex;
}

@media (width <= 48rem) {
  .footer__info--item {
    justify-content: center;
  }
}

.footer__info--item--icon {
  width: 2.4rem;
  height: 2.4rem;
  display: block;
}

.footer__info--item--desc {
  flex-direction: column;
  gap: .6rem;
  font-size: 1.6rem;
  display: flex;
}

@media (width <= 56.25em) {
  .footer__info--item--desc {
    font-size: 1.2rem;
  }
}

@media (width <= 48rem) {
  .footer__info--item--desc {
    font-size: 1.6rem;
  }
}

.footer__info--item--desc a {
  color: #9ec3eb;
  transition: all .3s;
  display: block;
}

.footer__info--item--desc a:hover {
  transform: scale(1.1);
}

.footer__info--item--desc span {
  text-align: center;
  display: block;
}

/*# sourceMappingURL=index.9c36a462.css.map */
